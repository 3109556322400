import React from "react";
import Tannhjul from "../images/tannhjul.png";
import ReactImage from "../images/react.png";
import RocketImage from "../images/rocket.png";
import OptimizerImage from "../images/optimiser.png";

function YourMainComponent() {
  return (
    <div className="mx-auto mb-20 text-black">
      <div className="container mx-auto p-10 flex flex-col md:flex-row items-center md:space-x-20 space-y-6 md:space-y-0">
        <h1 className="text-4xl font-bold">
          Hvorfor velge oss for ditt design?
        </h1>
        <p className="text-2xl md:flex-2 pr-4">
          Vi kombinerer kreativitet med teknisk ekspertise for unike designløsninger. Velg oss for kvalitet, innovasjon og en visuell opplevelse som skiller seg ut.
        </p>
      </div>

      <div className="mx-auto mb-20 text-black">
      <div className="container mx-auto p-10 flex items-center space-x-10">
        <div className="grid md:grid-cols-4 grid-cols-1 gap-10 flex-grow">
          {[
  {
    title: "Hva vi tilbyr",
    description: "Vi tilbyr skreddersydde nettsider og applikasjoner for private selskaper, restauranter og nettbutikker. Enten du ønsker en bestillingsplattform for din restaurant eller en effektiv nettløsning for din butikk, står vårt team klart til å møte dine digitale behov med kvalitet og presisjon.",
    image: Tannhjul, // Use the imported image
  },
  {
    title: "Fokus på React",
    description: "Vi spesialiserer oss på utvikling av nettsider ved bruk av React, og sikrer moderne, responsivt og høykvalitets design for alle våre prosjekter. Uansett omfang eller kompleksitet, er målet vårt alltid å skape nettsider som imponerer og leverer resultater.",
    image: ReactImage, // Use the imported image
  },
  {
    title: "Ekspertise og Effektivitet",
    description: "Med flere års erfaring i bransjen, er Webex Solutions i stand til raskt og enkelt å levere kvalitetsprodukter til våre kunder. Vår ekspertise sikrer at nettsider og applikasjoner møter bedriftens unike behov.",
    image: RocketImage, // Use the imported image
  },
  {
    title: "Optimalisering",
    description: "Optimalisering handler om å finjustere og forbedre for å oppnå best mulig resultater. Enten det gjelder nettsidens ytelse, brukeropplevelse eller søkemotorrangering, sikter vi mot det ypperste for å møte dine mål og forventninger.",
    image: OptimizerImage, // Use the imported image
  }
].map((block, index) => (
  <div className="flex flex-col space-y-4" key={index}>
    <img src={block.image} alt={`Block ${index + 1}`} className="w-20 h-20 mb-5 md:block hidden" />
    <h2 className="text-2xl font-bold">{block.title}</h2>
    <p className="text-xl mt-2 max-w-xs">{block.description}</p>
  </div>
))}

          </div>
        </div>
      </div>
    </div>
  );
}

export default YourMainComponent;
