import React, { useState } from "react";
import axios from "axios";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post("https://api.ordinalgenesis.xyz/DDX/contact", formData);
      alert("Message sent successfully!");
    } catch (error) {
      console.error("Error sending message", error);
      alert("Error sending message");
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form className="max-w-xl m-4 p-10 bg-white " onSubmit={handleSubmit}>
      <div className="mb-5">
        <label htmlFor="name" className="block mb-2 text-xl text-black">
          Navn
        </label>
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Navn"
          className="w-full px-3 py-2 border rounded-md"
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-5">
        <label htmlFor="email" className="block mb-2 text-xl text-black">
          Email
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Skriv inn din email"
          className="w-full px-3 py-2 border rounded-md"
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-5">
        <label htmlFor="message" className="block mb-2 text-xl text-black">
          Melding
        </label>
        <textarea
          name="message"
          id="message"
          rows="5"
          placeholder="Skriv din melding"
          className="w-full px-3 py-2 border rounded-md"
          onChange={handleChange}
          required
        />
      </div>
      <button
        type="submit"
        className="w-full py-2 px-4 bg-orange-500 text-white rounded-md hover:bg-orange-600 text-xl"
      >
        Send
      </button>
    </form>
  );
}

export default ContactForm;
