import React from "react";
import ContactForm from "../Components/ContactForm";
import TopBar from "../Components/TopBar";
import BottomBar from "../Components/BottomBar";

function Contact() {
  return (
    <div className="flex flex-col min-h-screen mx-auto bg-white">
      <TopBar />
      <div className="flex-1 ContactPage mt-20 flex flex-wrap justify-between p-10">
        <div className="flex-1 pr-10">
          <h2 className="text-4xl font-bold mb-4 ml-14">Ta Kontakt!</h2>
          <p className="text-xl  ml-14">
            Vi vil gjerne høre fra deg. Fyll ut skjemaet, så tar vi kontakt så snart som mulig.
          </p>
          <ContactForm />
        </div>

        <div className="flex flex-col w-3/6">
          <h2 className="text-4xl font-bold mb-4">Email</h2>
          <p className="text-xl">post@webexsolutions.no</p>
          <h2 className="text-4xl font-bold mb-4 mt-10">Telefon</h2>
          <p className="text-xl">Norge</p>
          <p className="text-xl mt-2">+47 967 42 184</p>
        </div>
      </div>
      <BottomBar />
    </div>
  );
}

export default Contact;
