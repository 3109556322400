import React from "react";
import LogoImage from "../images/logo.png";
import { Link } from "react-router-dom";

function BottomBar() {
  return (
    <div className="relative p-4 md:p-6 shadow-md flex flex-col md:flex-row justify-between items-center bg-gray-200 w-full z-50 bg-opacity-95 bottom-0">
      <div className="flex justify-start items-center mb-2 md:mb-0">
        <img src={LogoImage} alt="Logo" className="h-16 md:h-20 p-1" />
        <div className="flex flex-col md:flex-row ml-4 md:ml-8 items-center md:space-x-4">
          <Link to="/vår-erfaring" className="text-lg text-black hover:underline mb-2 md:mb-0">
            Vår erfaring
          </Link>
          <span className="text-black hidden md:inline-block">|</span>
          <Link to="/om-oss" className="text-lg text-black hover:underline mb-2 md:mb-0 md:mx-4">
            Om oss
          </Link>
          <span className="text-black hidden md:inline-block">|</span>
          <Link to="/Contact" className="text-lg text-black hover:underline">
            Kontakt Oss
          </Link>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between w-full md:w-auto items-center text-center md:text-left">
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mb-2 md:mb-0">
          <span className="text-sm text-gray-600"> +47 967 42 184</span>
          <span className="text-sm text-gray-600">
             post@webexsolutions.no
          </span>
        </div>
        <p className="text-sm text-gray-600 md:absolute md:bottom-6 md:right-6">
          &copy; 2023 Webex - Solutions. All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default BottomBar;
