import React, { useRef, useState } from "react";

function WebsitePreviews({ websites }) {
  const scrollContainerRef = useRef(null);
  const [isDown, setIsDown] = useState(false);
  let startX;
  let scrollLeft;

  const handleMouseDown = (e) => {
    setIsDown(true);
    startX = e.pageX - scrollContainerRef.current.getBoundingClientRect().left;
    scrollLeft = scrollContainerRef.current.scrollLeft;
  };

  const handleMouseUp = () => {
    setIsDown(false);
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.getBoundingClientRect().left;
    const walk = x - startX;
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <div className="bg-gray-200 w-full">
      <h2 className="text-5xl font-bold text-black mb-4">Vår erfaring</h2>
      <div className="relative my-12 mt-10">
        <button
          className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white rounded-full shadow-lg text-orange-500 font-bold md:text-5xl w-10 h-10 md:w-16 md:h-16 flex items-center justify-center ml-2 md:ml-6"
          onClick={() => {
            scrollContainerRef.current.scrollBy({
              left: -780,
              behavior: "smooth",
            });
          }}
        >
          ‹
        </button>
        <div
          className="flex overflow-x-auto space-x-4 md:space-x-10 hide-scroll-bar"
          ref={scrollContainerRef}
        >
          {websites.map((site, index) => (
            <div
              key={index}
              className="overflow-hidden bg-white rounded-md shadow-lg w-full md:w-9/12 lg:w-2/3 xl:w-7/12"
              style={{ height: "700px", flexShrink: 0 }}
            >
              <a href={site.url} target="_blank" rel="noopener noreferrer">
                <iframe
                  src={site.url}
                  title={`Website Preview ${index}`}
                  className="w-full"
                  style={{ height: "calc(100% - 150px)" }}
                  scrolling="yes"
                ></iframe>
              </a>
              <div className="flex justify-between p-4 text-xl md:text-2xl">
                <div>{site.design}</div>
                <div>{site.backend}</div>
                <div>{site.database}</div>
              </div>
              <a
                href={site.url}
                target="_blank"
                rel="noopener noreferrer"
                className="block p-4 text-2xl md:text-3xl font-bold hover:text-orange-500"
              >
                {site.title}
              </a>
            </div>
          ))}
        </div>

        <button
          className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white rounded-full shadow-lg text-orange-500 font-bold md:text-5xl w-10 h-10 md:w-16 md:h-16 flex items-center justify-center mr-2 md:mr-6"
          onClick={() => {
            scrollContainerRef.current.scrollBy({
              left: 780,
              behavior: "smooth",
            });
          }}
        >
          ›
        </button>
      </div>
    </div>
  );
}

export default WebsitePreviews;
