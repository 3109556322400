import React from "react";
import LogoImage from "../images/logo.png";
import SmallLogoImage from "../images/litenlogo.png";
import { Link } from "react-router-dom";

function TopBar() {
  return (
    <div className="p-2 shadow-md flex justify-between items-center bg-white fixed w-full z-50 bg-opacity-95 top-0">
      <Link to="/" className="ml-2 sm:ml-6 p-1">
        <img src={LogoImage} alt="Logo" className="h-20 hidden sm:block" />
        <img src={SmallLogoImage} alt="Logo" className="h-12 sm:hidden" />
      </Link>
      <div className="flex items-center space-x-2 sm:space-x-4 mr-2 sm:mr-6 lg:ml-auto">
        <Link to="/vår-erfaring" className="px-2 py-1 text-sm sm:text-lg text-black">
          Vår erfaring
        </Link>
        <span className="text-black hidden sm:inline-block">|</span>
        <Link to="/om-oss" className="px-2 py-1 text-sm sm:text-lg text-black">
          Om oss
        </Link>
        <span className="text-black hidden sm:inline-block">|</span>
        <Link
          to="/Contact"
          className="px-2 py-1 text-sm sm:text-lg text-white bg-orange-500 hover:bg-orange-600 rounded"
        >
          Kontakt
        </Link>
      </div>
    </div>
  );
}

export default TopBar;
