
import React from "react";
import "../App.css";
import TeamMember1 from "../images/team1.png";
import TeamMember2 from "../images/team2.png";
import TeamMember3 from "../images/team3.png";
import LeftImage from "../images/home2.jpg";
import ContactForm from "../Components/ContactForm";
import TopBar from "../Components/TopBar";
import WebsitePreviews from "../Components/WebsitePreviews";
import FeatureBlock from "../Components/FeatureBlock";
import BottomBar from "../Components/BottomBar";
import mac from "../images/office3.jpg";

function App() {
  const websiteData = [
    {
      url: "https://goblins.ordinalgenesis.xyz/",
      title: "Goblins Website",
      design: "Design",
      backend: "Backend",
      database: "Database",
    },
    {
      url: "https://ordinalgenesis.xyz/",
      title: "Ordinal Genesis",
      design: "UI/UX",
      backend: "Django",
      database: "PostgreSQL",
    },
    {
      url: "https://rugs.ordinalgenesis.xyz/",
      title: "Bitcoin Rugs",
      design: "Graphics",
      backend: "Node.js",
      database: "MongoDB",
    },
  ];

  return (
    <div className="mx-auto bg-white overflow-x-hidden">
      <div className="mx-auto bg-white">
        <TopBar />
      </div>

      <style>
  {`
    body {
      overflow-x: hidden;
    }
  `}
</style>

      <div
        className="mx-auto mb-6 sm:mb-20 relative bg-black text-black"
        style={{
          backgroundImage: `linear-gradient(to right, rgba(246, 246, 246, 1), rgba(246, 246, 246, 0.75)), url(${LeftImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="container mx-auto p-4 sm:p-10 relative z-10">
          <h1 className="text-4xl sm:text-5xl font-bold mb-4 sm:mb-8">Webex - Solutions</h1>
          <p className="text-xl sm:text-2xl mt-4 sm:mt-8">
            Vi er din pålitelige partner for nettsideutvikling, dedikert til å lage skreddersydde, effektive og estetisk tiltalende nettsider for alle typer bedrifter. Vårt erfarne team forstår dine unike behov og er her for å hjelpe deg med å lykkes online. La oss sammen skape en nettside som ikke bare ser bra ut, men også genererer resultater for din bedrift!
          </p>
          <div className="flex flex-wrap mt-4 sm:mt-8">
            <span className="text-black text-lg sm:text-xl font-semibold mr-4 mb-2">React</span>
            <span className="text-black text-lg sm:text-xl font-semibold mr-4 mb-2">JS</span>
            <span className="text-black text-lg sm:text-xl font-semibold mr-4 mb-2">MongoDB</span>
            <span className="text-black text-lg sm:text-xl font-semibold mr-4 mb-2">Node</span>
            <span className="text-black text-lg sm:text-xl font-semibold mr-4 mb-2">Express</span>
          </div>
        </div>
      </div>

      <div className="mx-auto mb-6 sm:mb-20 text-black">
        <div className="container mx-auto">
          <FeatureBlock />
        </div>
      </div>

      <div className="w-full mt-6 sm:mt-10 p-4 text-black bg-gray-200">
        <div className="container mx-auto">
          <WebsitePreviews websites={websiteData} />
        </div>
      </div>

      <div className="container mx-auto p-4 sm:p-10 bg-white">
        <h2 className="text-2xl sm:text-5xl font-bold text-black mb-4 sm:mb-6">Vår Tilnærming</h2>
        <p className="text-xl sm:text-2xl">
          Vi tror på åpen kommunikasjon og tett samarbeid med våre kunder. Gjennom vår metodikk sikrer vi at hvert prosjekt er tilpasset kundens unike behov, samtidig som vi opprettholder høy kvalitet og effektivitet i arbeidet vårt.
        </p>
      </div>

      <div className="mt-4 sm:mt-6 bg-white">
        <img
          src={mac}
          alt="Mac Image"
          className="w-full"
        />
      </div>

      <div className="container mx-auto p-4 sm:p-10 bg-white text-center">
        <h2 className="text-4xl sm:text-8xl text-black">La Oss Skape Noe Fantastisk</h2>
      </div>

      <BottomBar />
    </div>
  );
}

export default App;
