import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from "./pages/App"; // Your existing App.jsx serving as the HomePage
import Contact from "./pages/Contact"; // Your Contact page
import OmOss from "./pages/Om-Oss"; // Your Contact page
import VårErfaring from "./pages/Vår-Erfaring"; // Your Contact page

function Main() {
  return (
    <Router>
      <Switch>
        <Route path="/contact" component={Contact} />
        <Route path="/om-oss" component={OmOss} />
        <Route path="/Vår-Erfaring" component={VårErfaring} />
        <Route path="/" component={App} />
        {/* Add other routes as needed */}
      </Switch>
    </Router>
  );
}

export default Main;
