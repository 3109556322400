import React from "react";
import ContactForm from "../Components/ContactForm";
import TopBar from "../Components/TopBar";
import BottomBar from "../Components/BottomBar";
import WebsitePreviews from "../Components/WebsitePreviews";

function VårErfaring() {
  const websiteData = [
    {
      url: "https://goblins.ordinalgenesis.xyz/",
      title: "Goblins Website",
      design: "Design",
      backend: "Backend",
      database: "Database",
    },
    {
      url: "https://ordinalgenesis.xyz/",
      title: "Ordinal Genesis",
      design: "UI/UX",
      backend: "Django",
      database: "PostgreSQL",
    },
    {
      url: "https://rugs.ordinalgenesis.xyz/",
      title: "Bitcoin Rugs",
      design: "Graphics",
      backend: "Node.js",
      database: "MongoDB",
    },
  ];

  return (
    <div className="mx-auto bg-white">
      <TopBar />
      <div className="mt-20 flex flex-col md:flex-row justify-between p-4 md:p-10 w-full md:w-4/6 mx-auto">
        <div className="md:flex-1 md:pr-10">
          <h2 className="text-3xl md:text-5xl font-bold mt-7 mb-4">Om Oss</h2>
          <p className="text-xl md:text-2xl">
          Vi er et lidenskapelig team av tre, spesialisert i nettsidedesign og -utvikling. Med år av erfaring, har vi jobbet på prosjekter som krever avanserte sikkerhetsløsninger, fra enkle nettsteder til komplekse webapplikasjoner. Vi prioriterer datasikkerhet og garanterer estetisk og teknologisk solid nettsidebygging.
          </p>
        </div>
      </div>
      <div className="w-full mt-10 p-4 bg-gray-200 text-black">
        <div className="container mx-auto">
          <WebsitePreviews websites={websiteData} />
        </div>
      </div>
      <div className="mx-auto p-10 bg-white text-center">
        <h2 className="text-4xl md:text-8xl text-black">La Oss Skape Noe Fantastisk</h2>
      </div>
      <BottomBar />
    </div>
  );
}

export default VårErfaring;
