import React from "react";
import ContactForm from "../Components/ContactForm";
import TopBar from "../Components/TopBar";
import BottomBar from "../Components/BottomBar";
import office1 from "../images/office1.jpg";
import TeamMember1 from "../images/team1.png";
import TeamMember2 from "../images/team2.png";
import TeamMember3 from "../images/team3.png";
import Banner from "../images/screen.png";

function Contact() {
  return (
    <div className="mx-auto bg-white">
      <TopBar />
      <div className="ContactPage mt-10 sm:mt-20 flex flex-wrap justify-between p-4 sm:p-10 w-full">
      <div className="flex-1 pr-4 sm:pr-10 mt-4">
          <h2 className="text-5xl sm:text-7xl font-bold mb-4 ml-4 sm:ml-14">Om Oss</h2>
          <p className="text-xl sm:text-2xl ml-4 sm:ml-14 mt-6 max-w-5xl">
          Vi er et engasjert team av tre med bred erfaring innen nettsidedesign og -utvikling. Vi har jobbet med varierte prosjekter, fra enkle nettsteder til komplekse applikasjoner med strenge sikkerhetskrav. Vårt mål er alltid å beskytte dine data og levere estetisk tiltalende og teknisk solide nettsider.
          </p>
          <div className="flex flex-wrap mt-8 ml-4 sm:ml-14">
            <span className="text-xl sm:text-xl text-black font-semibold m-1">React</span>
            <span className="text-xl sm:text-xl text-black font-semibold m-1">JS</span>
            <span className="text-xl sm:text-xl text-black font-semibold m-1">MongoDB</span>
            <span className="text-xl sm:text-xl text-black font-semibold m-1">Node</span>
            <span className="text-xl sm:text-xl text-black font-semibold m-1">Express</span>
          </div>
        <img src={Banner} alt="Banner" className="w-full mt-4" />
      </div>
      </div>

      {/* About Us Section */}
      <div className="w-full p-4 bg-gray-200 text-black flex flex-col items-center">
        <h2 className="text-5xl sm:text-7xl font-bold mb-4">Bli kjent</h2>
        <p className="text-xl sm:text-2xl text-center max-w-5xl mt-10 mb-12">
          Vårt dedikerte team består av talentfulle utviklere, strategiske markedsførere,
          kreative designere og tekniske støtteeksperter som alle er drevet av en felles
          lidenskap: å hjelpe våre kunder med å oppnå suksess. Hver enkelt av oss er dedikert til å levere førsteklasses løsninger og
          tjenester for å sikre at våre kunders behov ikke bare blir møtt, men også overgått.
        </p>
        <div className="w-full sm:w-1/2 p-2">
          <img src={office1} alt="Office 1" className="w-full rounded-xl" />
        </div>
      </div>

      {/* Team Section */}
      <div className="container mx-auto p-4 text-white flex flex-col items-center">
        <h1 className="text-3xl sm:text-5xl font-bold text-black text-center mb-4">Team</h1>
        <div className="flex flex-wrap justify-center sm:space-x-4 mt-8">
          <div className="w-full sm:w-1/3 p-2 text-center">
            <img src={TeamMember1} alt="Team Member 1" className="w-full h-64 object-cover rounded-lg mb-2"/>
            <p className="text-xl sm:text-2xl text-black mb-2 font-semibold">Konrad</p>
            <p className="text-base sm:text-lg text-black">Fullstack utvikler</p>
          </div>
          <div className="w-full sm:w-1/3 p-2 text-center">
            <img src={TeamMember2} alt="Team Member 2" className="w-full h-64 object-cover rounded-lg mb-2"/>
            <p className="text-xl sm:text-2xl text-black mb-2 font-semibold">Sergiusz</p>
            <p className="text-base sm:text-lg text-black">Kunderådgiver</p>
          </div>
          <div className="w-full sm:w-1/3 p-2 text-center">
            <img src={TeamMember3} alt="Team Member 3" className="w-full h-64 object-cover rounded-lg mb-2"/>
            <p className="text-xl sm:text-2xl text-black mb-2 font-semibold">D*******</p>
            <p className="text-base sm:text-lg text-black">Fullstack utvikler</p>
          </div>
        </div>
      </div>

      {/* Amazing Section */}
      <div className="container mx-auto p-10 mt-5 bg-white text-center">
        <h2 className="text-5xl sm:text-7xl text-black">La Oss Skape Noe Fantastisk</h2>
      </div>

      <BottomBar />
    </div>
  );
}

export default Contact;
